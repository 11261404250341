<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'compare-compilations-index' }" />

    <template v-if="compilation">
      <h2 class="mb-4">Редактирование подборки сравнений</h2>

      <v-sheet class="pa-4" elevation="3">
        <compare-compilation-form
          :compilation="compilation"
          :loading="updating"
          @change-field="changeField"
          @submit="updateCompilation"
        />
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Подборка сравнений с id "{{ id }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import { CompareCompilation } from "~/libs/compare-compilation/compare-compilation";

import CompareCompilationForm from "~/components/compare-compilations/form";

export default {
  mixins: [editorForm],
  data() {
    return {
      compilation: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchCompilation();
  },
  computed: {
    id() {
      return this.$route.params.id;
    }
  },
  methods: {
    async fetchCompilation() {
      this.loading = true;

      try {
        const compilation = await this.$axios.$get(`compare-compilations/${this.id}`);

        this.compilation = new CompareCompilation(compilation);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Подборка сравнений с id: "${this.id}" не найдена ${html}`);
      } finally {
        this.loading = false;
      }
    },

    async updateCompilation() {
      if (this.updating) return;

      this.updating = true;

      try {
        await this.$axios.$put(`compare-compilations/${this.id}`, this.compilation);
        await this.fetchCompilation();

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.compilation)).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },

    changeField(payload) {
      this.commitChange(this.compilation, payload);
    }
  },
  components: {
    CompareCompilationForm
  }
};
</script>
